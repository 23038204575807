.pdf embed, iframe, object{
    height: 140vh;
    width: 100%;
}
*, body{
    background-color: black;
}
.react-pdf_Page_Canvas{
    background-color: black;
}

.container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
}

@media screen and (max-width: 768px){
    .pdf embed, iframe, object{
        height: 60vh;   
    }
    
}