.navbarContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  width: 100%;
  padding: 3rem 5rem;
  color: white;
  font-size: 30px;
}

.brand {
  display: flex;
  align-items: center;
}

.brand .logo {
  margin-right: 1rem;
}

.brand .name {
  color: white;
  font-size: 1.5rem; /* Example font size */
}
.name a{
  text-decoration: none;
  color: white;
}

.right {
  display: flex;
  align-items: center;
}

.right .howItWorks {
  font-size: 18px;
  margin-right: 2rem; /* Adjust as needed */
}

.right .auth .login {
  cursor: pointer;
  border: 1px solid white;
  border-radius: 0.5rem;
  padding: .75rem;
  font-size: 18px;
}

.right .auth .login:hover {
  background-color: white;
  color: black;
  transition: .2s ease-in-out;
}

@media screen and (max-width: 768px){
  .navbarContainer{
    padding: 2rem 2rem;
  }
  .brand .name {
    font-size: 1.2rem; /* Example font size */
  }
  .right .howItWorks {
    display: none;
  }
  .right .auth .login {
    padding: .5rem;
    font-size: 16px;
  }
}
